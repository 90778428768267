import React from 'react'
import { Tabs } from 'react-tabs';
import GatsbyImage from 'gatsby-image'
import ContactUs from '../ContactUs'
import RichText from '../RichText'

class TransformatiionStoryTemplateDetails extends React.Component {
    componentDidMount() {

    }
    render() {
        const transformationStoryTemplateData = this.props.data
        const transformationStory = transformationStoryTemplateData.allKontentItemTransformation.nodes[0]

        const transformationStoryBlock = (
            <div>
                <section className="services-page">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Tabs className="ui-tabs ui-widget ui-widget-content ui-corner-all">
                                    <div className="row">
                                        <div className="col-lg-9 order-2">
                                            <section className="tabs-content">
                                                <div className="service-thumb">
                                                    {transformationStory.elements.teaser_image.value[0] ? (
                                                        <GatsbyImage
                                                            fluid={transformationStory.elements.teaser_image.value[0].fluid}
                                                            alt={transformationStory.elements.teaser_image.value[0].description}
                                                            style={{ height: '300px' }}
                                                        />
                                                    ) : ""}
                                                </div>
                                                <div className="down-content">
                                                    <RichText data={transformationStory.elements.long_description} />
                                                </div>
                                            </section>
                                        </div>
                                        <div className="col-lg-3 order-1">
                                            <div className="services-sidebar">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <ContactUs />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )

        return (
            <div>
                {transformationStoryBlock}
            </div>
        )
    }
}

export default TransformatiionStoryTemplateDetails
