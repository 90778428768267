import React from 'react'
import Meta from '../components/Meta'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import TranformationStoryTemplateDetails from '../components/TransformationStoryTemplateDetails'
import Header from '../components/Header'
import PageHeading from '../components/PageHeading'
import Footer from '../components/Footer'

class TransformationStoryTemplate extends React.Component {
    render() {
        const transformationStoryTemplateData = this.props.data
        const transformationStory = transformationStoryTemplateData.allKontentItemTransformation.nodes[0]
        const title = transformationStoryTemplateData.kontentItemSiteMetadata.elements.title.value
        const subtitle = transformationStory.elements.title.value

        return (
            <Layout>
                <div>
                    <Meta title={subtitle} postfix={title} description={subtitle} />
                    <Header />
                    <PageHeading data={subtitle} />
                    <TranformationStoryTemplateDetails {...this.props} />
                    <Footer />
                </div>
            </Layout>
        )
    }
}

export default TransformationStoryTemplate

export const pageQuery = graphql`
  query TransformationBySlug($slug: String!) {
    kontentItemSiteMetadata(system: {codename: {eq: "site_metadata"}}) {
      elements {
        subtitle {
          value
        }
        title {
          value
        }
      }
    }
    allKontentItemTransformation(filter: {elements: {slug: {value: {eq: $slug}}}}) {
      nodes {
        system {
          id
        }
        elements {
            slug {
              value
            }
            title {
              value
            }
            long_description {
              value
              links {
                link_id
                url_slug
                type
              }
              images {
                image_id
                description
                url
                fluid(maxWidth: 700) {
                  ...KontentAssetFluid
                }
              }
              modular_content {
                ... on kontent_item_component_images_wrapper {
                  id
                  elements {
                    description {
                      value
                    }
                    photos {
                      value {
                        fluid(maxWidth: 350) {
                          aspectRatio
                          ...KontentAssetFluid
                        }
                        fixed(height: 700) {
                          aspectRatio
                          src
                          ...KontentAssetFixed
                        }
                        description
                        url
                      }
                    }
                  }
                  system {
                    codename
                    type
                  }
                }
              }           
            }    
            teaser_image {
              value {
                url
                description
                fluid(maxWidth: 810) {
                  ...KontentAssetFluid
                }
              }
            }
        }
      }
    }
  }
`
